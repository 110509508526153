import { Component, OnDestroy, OnInit } from '@angular/core';
import { Template } from 'src/app/models/template.model';
import { selectTemplates } from 'src/app/store/repository/repository.selector';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar-doc',
  templateUrl: './sidebar-doc.component.html',
  styleUrls: ["./sidebar-doc.component.scss"]
})
export class SidebarDocComponent implements OnInit {
  templates: Template[];
  templatesOnSaleList: string[];

  currentUserId: string;

  templates$: Observable<Template[]>;
  piFunctionOnSale: boolean;
  piTransferOnSale: boolean;
  piProfileOnSale: boolean;

  constructor(private store: Store) {
  }

  ngOnInit() {
    this.templates$ = this.store.select(selectTemplates);
  }
}
