import { Component, OnDestroy, OnInit } from '@angular/core';
import { Template } from 'src/app/models/template.model';

@Component({
  selector: 'app-footer-doc',
  templateUrl: './footer-doc.component.html',
  styleUrls: ["./footer-doc.component.scss"]
})
export class FooterDocComponent implements OnInit {
  template: Template;
  idTemplate: string | null = null;
  year: number = new Date().getFullYear();

  currentUserId: string;

  constructor() {}

  ngOnInit(): void {

  }
}
