<body>
<div class="container-fluid bg-white">
  <div class="row bg-light justify-content-center">
    <app-sidebar-doc></app-sidebar-doc>
    <div class="col bg-white p-4">
      <main id="main-doc">
        <router-outlet></router-outlet>
      </main>
      <hr>
      <app-footer-doc></app-footer-doc>
    </div>

  </div>
</div>
</body>
