<div class="col sidebar pt-sm-4 pb-4 sticky">
  <nav id="navbar" class="d-flex justify-content-center">
    <ul class="nav flex-column w-75">
      <li class="nav-item mt-2">
        <span class="icon-nav icon icon-md icon-shape text-primary">
          <i class="ni ni-button-play"></i>
        </span>
        <h3 class="mb-0">{{'doc.getting_started'|translate}}</h3>
      </li>
      <li class="nav-item"><a routerLinkActive="active bg-primary text-white" [routerLink]="['/doc/introduction']">{{'doc.introduction'|translate}}</a></li>
      <li class="nav-item"><a routerLinkActive="active bg-primary text-white" [routerLink]="['/doc/micro-apps']">{{'doc.micro_apps'|translate}}</a></li>
      <li class="nav-item"><a routerLinkActive="active bg-primary text-white" [routerLink]="['/doc/domain']">{{'doc.hosting'|translate}}</a></li>
      <!-- <li class="nav-item"><a routerLinkActive="active bg-primary text-white" [routerLink]="['/doc/subscription']">{{'doc.subscription'|translate}}</a></li> -->
      <li class="nav-item"><a routerLinkActive="active bg-primary text-white" [routerLink]="['/doc/pianalytics']">{{'doc.pianalytics'|translate}}</a></li>
      <li class="nav-item"><a routerLinkActive="active bg-primary text-white" [routerLink]="['/doc/pirog-page-builder']">{{'doc.page_builder'|translate}}</a></li>
      <!-- <li class="nav-item"><a routerLinkActive="active bg-primary text-white" [routerLink]="['/doc/pricing']">Pricing</a></li> -->

      <li *ngFor="let template of templates$ | async" class="nav-item mt-2">
        <img class="img-custom" src="assets/templates/{{template.name}}/icone_color-min.webp" style="height: 20px;">
        <h3 class="mb-0">{{ template.name }}</h3>
        <ul class="nav flex-column">
          <li class="nav-item"><a routerLinkActive="active bg-primary text-white" [routerLink]="['/doc/', template.name.toLowerCase() + '-quick-start']">{{'doc.quick_start'|translate}}</a></li>
          <li *ngIf="template.name == 'piFunction'" class="nav-item"><a routerLinkActive="active bg-primary text-white" [routerLink]="['/doc/', template.name.toLowerCase() + '-examples']">Examples</a></li>
        </ul>
      </li>
    </ul>
  </nav>
</div>
