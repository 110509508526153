import { Component } from '@angular/core';
import { Template } from 'src/app/models/template.model';

@Component({
  selector: 'app-doc',
  templateUrl: './doc.component.html',
  styleUrls: ["./doc.component.scss"]
})
export class DocComponent {
  template: Template;
  idTemplate: string | null = null;
  currentUserId: string;

  constructor() {}
}
